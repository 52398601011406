// Full Calendar Plugins
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

// eslint-disable-next-line object-curly-newline
import { ref, computed, watch, onMounted } from '@vue/composition-api';
import store from '@/store';

export default function userCalendar() {
    // Use toast
    const toast = useToast();
    // ------------------------------------------------
    // refCalendar
    // ------------------------------------------------
    const refCalendar = ref(null);

    // ------------------------------------------------
    // calendarApi
    // ------------------------------------------------
    let calendarApi = null
    onMounted(() => {
        calendarApi = refCalendar.value.getApi()
    });

    // ------------------------------------------------
    // refetchEvents
    // ------------------------------------------------
    const refetchEvents = () => {
        calendarApi.refetchEvents();
    };

    // ------------------------------------------------
    // selectedCalendars
    // ------------------------------------------------
    const selectedCalendars = computed(() => store.state.calendar.selectedCalendars);

    watch(selectedCalendars, () => {
        refetchEvents();
    });

    // --------------------------------------------------------------------------------------------------
    // AXIOS: fetchEvents
    // * This will be called by fullCalendar to fetch events. Also this can be used to refetch events.
    // --------------------------------------------------------------------------------------------------
    const fetchEvents = (info, successCallback) => {
        // If there's no info => Don't make useless API call
        if (!info) return

        // Fetch Events from API endpoint
        store
            .dispatch('calendar/fetchEvents', {
                calendars: selectedCalendars.value
            })
            .then(response => {
                successCallback(response.data);
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error al obtener la lista de reservas en el calendario',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger'
                    }
                });
            });
    };

    // ------------------------------------------------------------------------
    // calendarOptions
    // * This isn't considered in UI because this is the core of calendar app
    // ------------------------------------------------------------------------
    const calendarOptions = ref({
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        initialView: 'dayGridMonth',
        headerToolbar: {
            start: 'sidebarToggle, prev,next, title',
            end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
        },
        locale: esLocale,
        events: fetchEvents,

        /*
          Max number of events within a given day
          ? Docs: https://fullcalendar.io/docs/dayMaxEvents
        */
        dayMaxEvents: 5,

        /*
          Determines if day names and week names are clickable
          ? Docs: https://fullcalendar.io/docs/navLinks
        */
        navLinks: true,

        eventClassNames() {
            return ['bg-light-info']
        },

        rerenderDelay: 350
    });

    // ------------------------------------------------------------------------

    return {
        refCalendar,
        calendarOptions,
        refetchEvents,
        fetchEvents
    }
}