<template>
    <div class="app-calendar overflow-hidden border">
        <div class="row no-gutters">

            <!-- Calendar -->
            <div class="col position-relative">
                <div class="card shadow-none border-0 mb-0 rounded-0">
                    <div class="card-body pb-0">
                        <full-calendar
                            locale="es"
                            ref="refCalendar"
                            :options="calendarOptions"
                            class="full-calendar"
                        />
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

    import calendarStoreModule from './calendarStoreModule';
    import { onUnmounted } from '@vue/composition-api';
    import FullCalendar from '@fullcalendar/vue';
    import useCalendar from './useCalendar';
    import store from '@/store';

    export default {
        components: {
            FullCalendar // make the <FullCalendar> tag available
        },
        setup() {
            const CALENDAR_APP_STORE_MODULE_NAME = 'calendar';

            // Register module
            if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule);

            // UnRegister on leave
            onUnmounted(() => {
            if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
            });

            const {
                refCalendar,
                fetchEvents,
                refetchEvents,
                calendarOptions
            } = useCalendar();

            fetchEvents();

            return {
                refCalendar,
                refetchEvents,
                calendarOptions
            }
        }
    }

</script>

<style lang="scss">
    @import "@core/scss/vue/apps/calendar.scss";
</style>
