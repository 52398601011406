import axios from '@axios';

export default {
    namespaced: true,
    state: {
        calendarOptions: [{
                color: 'danger',
                label: 'Personal'
            },
            {
                color: 'primary',
                label: 'Business'
            },
            {
                color: 'warning',
                label: 'Family'
            },
            {
                color: 'success',
                label: 'Holiday'
            },
            {
                color: 'info',
                label: 'ETC'
            }
        ],
        selectedCalendars: ['Personal', 'Business', 'Family', 'Holiday', 'ETC']
    },
    getters: {},
    mutations: {
        SET_SELECTED_EVENTS(state, val) {
            state.selectedCalendars = val
        }
    },
    actions: {
        fetchEvents(ctx, { calendars }) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/bookings/calendar', {
                        params: {
                            calendars: calendars.join(',')
                        }
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            })
        }
    }
}